<template>
  <div class="min-h-screen">
    <NuxtLoadingIndicator color="#FFFFFF" />
    <SideBar />
    <div
      class="ml-24"
      :class="{
        'lg:ml-60': !isOpenSideNav,
      }"
    >
      <Page>
        <slot />
      </Page>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGeneralStore } from '~/stores/general'

const generalStore = useGeneralStore()
const { isOpenSideNav } = storeToRefs(generalStore)
</script>
